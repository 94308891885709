/* Globales Styling für den gesamten Body */
body {
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Header Styling */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  padding: 1rem 2rem;
}

/* Styling für das Logo (Jewel AI) */
header .logo h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
}

/* Styling für die Navigationslinks */
header nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

header nav ul li {
  display: inline;
}

header nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

header nav ul li a:hover {
  color: #007bff;
}

/* Main Container: Kein Flex-Layout für das gesamte Main */
.main-container {
  display: block;
  padding-left: 4vw;
  padding-right: 4vw;
}

/* Erster Abschnitt: Text und Bild nebeneinander */
.first-section {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  /*padding: 10px; /* Optional: Padding hinzufügen */
  margin-bottom: 4rem; /* Behalte den unteren Rand bei, wenn gewünscht */
}

.text-section, .image-section {
  flex: 1;
}

.text-section {
  text-align: left;
}

.text-section h2 {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.text-section p {
  font-size: 1.2rem;
  line-height: 1.2;
}

.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-section img {
  width: 100%; /* Standardbreite für größere Bildschirme */
  /*height: 80vw; /* Beibehaltung des Seitenverhältnisses */
  padding: 0px; /* Optional: Padding */
  object-fit: cover; /* Füllt die Box aus, ohne das Seitenverhältnis zu verlieren */
}

.first-section .image-section {
  padding: 0px; /* Optional: Padding */
  height: 100%; /* Erhöhe die Höhe des Containers */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* How It Works Section */
.second-section {
 /* font-size: 3vh;*/
  font-weight: 900;
  width: 100%; /* Setze die Breite auf 100% */
  background-color: #f9f9f9;
  /*padding: 2rem 0; /* Padding oben und unten, aber nicht seitlich */
  text-align: center;
  margin: 0; /* Entferne die Margins, um Überlappungen zu vermeiden */
  margin-bottom: 8rem;
  padding-top: 2rem;
  box-sizing: border-box; /* Stelle sicher, dass Padding und Border in der Breite enthalten sind */
}

.tiles-container {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto; /* Zentriere die Kacheln */
  max-width: 95%; /* Maximalbreite, um die Kacheln mittig zu halten */
  padding-bottom: 4rem;

}

.tile {
  flex: 1;
  min-width: 250px;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(57, 48, 48, 0.1);
  text-align: left;
}

.tile h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.tile p {
  font-size: 1rem;
  line-height: 1.4;
}

/* Footer Styling */
footer {
  text-align: center; /* Zentriert den Text im Footer */
  padding: 20px; /* Abstand im Footer */
  background-color: #f4f4f4; /* Hintergrundfarbe des Footers */
}

.start-button {
  background: linear-gradient(45deg, #a2cbf4, #1DA1F2); /* Hellblauer Farbverlauf */
  background-size: 300% 300%; /* Erhöht die Größe des Hintergrunds für die Animation */
  color: black; /* Textfarbe */
  padding: 15px 30px; /* Größerer Innenabstand */
  border: none; /* Kein Rahmen */
  border-radius: 5px; /* Abgerundete Ecken */
  cursor: pointer; /* Zeiger ändern beim Hover */
  font-size: 18px; /* Größere Schriftgröße */
  font-weight: 900;
  margin-top: 1rem; /* Abstand nach oben */
  animation: gradient 8s ease infinite; /* Animation für den Farbverlauf */
}

.start-button:hover {
  background: #333; /* Anthrazit Hintergrund beim Hover */
  color: white; /* Schriftfarbe wird weiß beim Hover */
}

/* Farbverlauf Animation */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.price-info {
  font-size: 14px !important; /* Setze die Schriftgröße auf 14px mit !important */
  color: #666; /* Graue Schriftfarbe */
  margin-top: 0.5rem; /* Abstand nach oben */
}

.logo {
  font-size: 2rem; /* Beispielgröße, passe sie nach Bedarf an */
  color: #333; /* Textfarbe für den Rest des Logos */
}

/* Spezifische Regel für Emojis, um sicherzustellen, dass sie nicht beeinflusst werden */
.logo span {
  color: inherit; /* Erbt die Textfarbe */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Media Query für kleinere Bildschirme */
@media (max-width: 768px) {
  .first-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .image-section {
    margin-top: 1.5rem;
  }

  .image-section img {
   max-width: 100%;
  }

  .tiles-container {
    flex-direction: column;
  }

  .tile {
    width: auto;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 600px) { /* Für Bildschirme mit einer Breite von 600px oder weniger */
  .first-section .image-section {
    /*height: 200vw; /* Setze die Höhe auf 400px oder einen anderen gewünschten Wert */
   /* width: 100%; /* Setze die Breite auf 100%, wenn gewünscht */
  }

  .first-section .image-section img {
    /*width: 150%; /* Setze die Breite auf 150% für kleinere Bildschirme */
    /*height: 80vw; /* Beibehaltung des Seitenverhältnisses */
    /*justify-content: center; /* Zentriert den Inhalt horizontal */
    /*align-items: center; /* Zentriert den Inhalt vertikal */

  }

}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Bildgalerie Styling */
.gallery-title {
 /* font-size: flex;*/
  font-weight: 900;
  text-align: center; /* Zentriert die Überschrift */
  margin: 20px 0; /* Abstand oben und unten */
  color: #333; /* Schriftfarbe */
  margin-top: 40px; /* Abstand über der zweiten Galerie */
}

.gallery {
  display: flex; /* Aktiviert Flexbox */
  flex-wrap: wrap; /* Erlaubt das Umwickeln der Elemente */
  gap: 10px; /* Abstand zwischen den Bildern */
  justify-content: center; /* Zentriert die Galerie */
}

.gallery-item {
  flex: 1 1 calc(50% - 10px); /* Jedes Bild nimmt 50% der Breite minus den Abstand ein */
  box-sizing: border-box; /* Stellt sicher, dass Padding und Border in der Breite enthalten sind */
}

.gallery-item img {
  width: 100%; /* Setzt die Breite des Bildes auf 100% des Containers */
  height: auto; /* Beibehaltung des Seitenverhältnisses */
  border-radius: 8px; /* Optional: Abgerundete Ecken */
}

.gallery-item p {
  text-align: center; /* Zentriert den Titel */
  margin-top: 5px; /* Abstand über dem Titel */
  font-size: 1rem; /* Schriftgröße für den Titel */
  color: #333; /* Schriftfarbe */
  font-style: italic; /* Macht den Titel kursiv */
}
.footer {
  background-color: #f4f4f4; /* Hintergrundfarbe des Footers */
  padding: 20px; /* Abstand im Footer */
}

.footer-content {
  display: flex; /* Aktiviert Flexbox */
  justify-content: space-between; /* Verteilt den Platz zwischen den Elementen */
  align-items: center; /* Zentriert die Elemente vertikal */
}

.twitter-link {
  text-decoration: none; /* Entfernt die Unterstreichung */
  color: #1DA1F2; /* Twitter-Farbe */
  font-size: 1rem; /* Schriftgröße */
}

.twitter-icon {
  width: 20px; /* Setze die Breite des Symbols */
  height: auto; /* Beibehaltung des Seitenverhältnisses */
  margin-left: 5px; /* Abstand zwischen Text und Symbol */
  vertical-align: middle; /* Vertikale Ausrichtung des Symbols */
}

nav {
  display: flex;
  gap: 20px; /* Adjust the gap between links */
}
